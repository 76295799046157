import React, { Fragment } from "react";
import { graphql } from "gatsby";
import Markdown from "markdown-to-jsx";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { groupByList } from "../utils/utils";
import * as css from "../styles/About.module.scss";
import * as cssButton from "../styles/ButtonBase.module.scss";
import Layout from "../components/Layout";

import MagBodard from "../assets/images/books/Mag-Bodard-portrait-d-une-productrice.webp";
import PierreChevalier from "../assets/images/books/Pierre-Chevalier-l-homme-des-poibles.webp";
import Email from "@mdi/svg/svg/email.svg";
import ButtonBase from "../components/ButtonBase";

import I18n from "../components/I18n";

const googleApiKey = "AIzaSyARgcnzUrEOOggQFBvSs-U202e3nQbsvLQ";
const defaultBooks = [
  {
    title: "Mag Bodard : portrait d'une productrice",
    author: { firstName: "Philippe", lastName: "Martin" },
    edition: "La Tour Verte",
    releaseDate: "2013-10-10",
    image: MagBodard,
    pages: 227,
    suppliers: [
      {
        name: "lalibrairie",
        link: "https://www.lalibrairie.com/livres/mag-bodard--portrait-d-une-productrice_0-1655885_9782917819234.html",
      },
      {
        name: "Fnac",
        link: "https://livre.fnac.com/a5843879/Philippe-Martin-Mag-Bodard-portrait-d-une-productrice",
      },
      { name: "amazon", link: "https://www.amazon.fr/dp/2917819235" },
    ],
  },
  {
    title: "Pierre chevalier, l'homme des possibles - entretiens",
    author: { firstName: "Philippe", lastName: "Martin" },
    edition: "Séguier - Arte Édition",
    releaseDate: "2017-10-10",
    pages: 220,
    image: PierreChevalier,
    suppliers: [
      {
        name: "lalibrairie",
        link: "https://www.lalibrairie.com/livres/pierre-chevalier--l-homme-des-possibles-entretiens-avec-philippe-martin_0-3758471_9782840497301.html",
      },
      {
        name: "Fnac",
        link: "https://livre.fnac.com/a10215864/Philippe-Martin-Pierre-chevalier-l-homme-des-possibles-entretiens",
      },
      {
        name: "amazon",
        link: "https://www.amazon.fr/Pierre-Chevalier-Entretiens-Philippe-Martin/dp/2840497301",
      },
    ],
  },
];

const getTeamName = (id) =>
  ({
    pelleas: "Les Films Pelléas",
    balthuslab: "Balthus Lab",
  }[id]);

const Contact = ({
  pageContext: { locale, locales },
  location: { pathname },
  data: {
    strapi: {
      theme = {},
      staffMembers: { data: staffMembers = [] } = {},
      about: {
        data: {
          attributes: {
            mainLogo,
            name,
            description,
            email,
            address,
            phone,
            projectsText,
          },
        },
      } = {},
      books = defaultBooks,
    } = {},
  } = {},
}) => {
  return (
    <Layout
      theme={theme}
      className={css.About}
      locale={locale}
      locales={locales}
      pathname={pathname}
    >
      {(mainLogo?.data?.attributes?.fileS3 || description) && (
        <section className="about">
          {mainLogo?.data?.attributes?.fileS3 && (
            <h2>
              <GatsbyImage
                image={getImage(mainLogo?.data?.attributes?.fileS3)}
                alt="Les Films Pelléas"
                title="Les Films Pelléas"
              />
            </h2>
          )}
          <Markdown options={{ wrapper: null }}>{description}</Markdown>
        </section>
      )}
      {(staffMembers ?? []).length > 0 && (
        <section className="employees">
          <I18n id="title.staff_members" component="h2" />
          {groupByList(
            staffMembers ?? [],
            ({ attributes: { team } }) => team
          ).map(([id, team]) => (
            <Fragment key={id}>
              <div className="team">{getTeamName(id)}</div>
              <div className="members">
                {groupByList(team, ({ attributes: { role } }) => role).map(
                  ([role, members]) => (
                    <div key={role}>
                      <span>{role}</span>
                      {members.map(
                        ({
                          id,
                          attributes: { firstName, lastName, email },
                        }) => (
                          <span className="member" key={id}>
                            <span>
                              {firstName} {lastName}
                            </span>
                            <ButtonBase
                              className={cssButton.IconButton}
                              component="a"
                              href={`mailto:${email}`}
                            >
                              <Email />
                            </ButtonBase>
                          </span>
                        )
                      )}
                    </div>
                  )
                )}
              </div>
            </Fragment>
          ))}
        </section>
      )}
      {name && (
        <section className="contact">
          {[name, email, address, phone]
            .filter((v) => v)
            .map((v) => (
              <div key={v}>{v}</div>
            ))}
          <iframe
            title={`${name}: ${address}"`}
            height="250"
            src={`https://www.google.com/maps/embed/v1/place?q=${
              name ?? address
            }&key=${googleApiKey}`}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </section>
      )}
      {projectsText?.length > 1 && (
        <section className="projects">
          <I18n id="title.contact.projects" component="h2" />
          <Markdown options={{ wrapper: null }}>{projectsText}</Markdown>
        </section>
      )}
      <section className="books">
        <I18n id="title.contact.books" component="h2" />
        {books.map(
          ({
            title: id,
            title,
            author: { firstName, lastName },
            edition,
            releaseDate,
            pages,
            suppliers,
            image,
          }) => (
            <div key={id}>
              <img src={image} alt="" />
              <p>{title}</p>
              <p>
                {firstName} {lastName}
              </p>
              <p>
                {new Date(releaseDate).toLocaleString("fr-FR", {
                  year: "numeric",
                })}
                , {edition}
              </p>
              <p>{String(pages)} pages</p>
              {suppliers?.length > 0 && (
                <div className="suppliers">
                  {suppliers.map(({ name: id, name, link }) => (
                    <a
                      key={id}
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {name}
                    </a>
                  ))}
                </div>
              )}
            </div>
          )
        )}
      </section>
    </Layout>
  );
};

export const query = graphql`
  query ($locale: Strapi_I18NLocaleCode!) {
    strapi {
      about(locale: $locale) {
        data {
          id
          attributes {
            mainLogo: logo_main {
              data {
                id
                attributes {
                  url
                  formats
                  fileS3 {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 90
                        width: 200
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
            name
            description
            email
            address
            phone
            projectsText: projects_text
          }
        }
      }
      staffMembers(locale: $locale) {
        data {
          id
          attributes {
            team
            firstName: first_name
            lastName: last_name
            role
            email
          }
        }
      }
    }
  }
`;

export default Contact;
